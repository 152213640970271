import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faDownload,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";

import "./DocumentsModal.css";

const DocumentsModal = ({ isOpen, onClose, isAdmin, userDetails }) => {
  const [activeTab, setActiveTab] = useState("view");

  // States for customers and properties
  const [availableCustomers, setAvailableCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [availableProperties, setAvailableProperties] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState([]); // Allow multiple selections
  const [isCustomersLoading, setIsCustomersLoading] = useState(false);
  const [isPropertiesLoading, setIsPropertiesLoading] = useState(false);

  // Search text
  const [customerSearchText, setCustomerSearchText] = useState("");
  const [propertySearchText, setPropertySearchText] = useState("");

  // Dropdown open/close state
  const [isCustomerDropdownOpen, setIsCustomerDropdownOpen] = useState(false);
  const [isPropertyDropdownOpen, setIsPropertyDropdownOpen] = useState(false);

  // Upload file state
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadMessage, setUploadMessage] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  // Refs for handling outside clicks
  const customerDropdownRef = useRef(null);
  const propertyDropdownRef = useRef(null);

  const [documents, setDocuments] = useState([]);
  const [isDocumentsLoading, setIsDocumentsLoading] = useState(false);
  const [error, setError] = useState(null);

  const resetState = () => {
    setActiveTab("view");
    setAvailableCustomers([]);
    setSelectedCustomer(null);
    setAvailableProperties([]);
    setSelectedProperties([]);
    setUploadMessage("");
    setDocuments([]);
    setError(null);
  };

  const fetchCustomersByEmail = async (email, token) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/users/usercustomerbyemail/${email}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const customerNames = response.data.customers;
      if (!customerNames || customerNames.length === 0) {
        console.error("No customers found for this email.");
        return [];
      }

      return customerNames[0].CUSTOMER_NAME.map((name, index) => ({
        id: customerNames[0].BUILDOPS_CUSTOMER_ID[index],
        name,
      }));
    } catch (error) {
      console.error("Error fetching customers by email:", error);
      return [];
    }
  };

  useEffect(() => {
    if (!isOpen) {
      resetState();
      return;
    }

    const fetchCustomers = async () => {
      setIsCustomersLoading(true);
      try {
        const token = localStorage.getItem("token");

        if (isAdmin) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/sigma/customers`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          const sortedCustomers = response.data.customer_names
            .map((name, index) => ({
              id: response.data.customer_ids[index],
              name,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
          setAvailableCustomers(sortedCustomers);

          // Preselect all customers
          setSelectedCustomer(sortedCustomers.map((customer) => customer.id));
        } else {
          const customerNames = await fetchCustomersByEmail(
            userDetails.email,
            token
          );
          setAvailableCustomers(customerNames);

          // Preselect the first customer for non-admin users
          if (customerNames.length > 0) {
            setSelectedCustomer(customerNames[0].id);
          }
        }
      } catch (error) {
        console.error("Failed to fetch customers:", error);
      } finally {
        setIsCustomersLoading(false);
      }
    };

    fetchCustomers();
  }, [isOpen, isAdmin, userDetails.email]);

  useEffect(() => {
    if (!selectedCustomer) {
      setAvailableProperties([]);
      setSelectedProperties([]);
      return;
    }

    const fetchProperties = async () => {
      setIsPropertiesLoading(true);
      try {
        const token = localStorage.getItem("token");

        if (isAdmin) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/sigma/buildings/${selectedCustomer}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          const sortedProperties = response.data.property_names
            .map((name, index) => ({
              id: response.data.property_ids[index],
              name,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
          setAvailableProperties(sortedProperties);

          // Preselect all properties
          setSelectedProperties(
            sortedProperties.map((property) => property.id)
          );
        } else {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/users/getproperty/${userDetails.email}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );

          const user = response.data.user[0];
          const propertyNames = user.BUILDOPS_PROPERTY_IDS.map((id, index) => ({
            id,
            name: user.PROPERTIES[index],
          }));
          setAvailableProperties(propertyNames);

          // Preselect all properties
          setSelectedProperties(propertyNames.map((property) => property.id));
        }
      } catch (error) {
        console.error("Failed to fetch properties:", error);
      } finally {
        setIsPropertiesLoading(false);
      }
    };

    fetchProperties();
  }, [selectedCustomer, isAdmin, userDetails.email]);

  useEffect(() => {
    if (selectedProperties.length === 0) {
      setDocuments([]);
      return;
    }

    const fetchDocuments = async () => {
      setIsDocumentsLoading(true);
      try {
        const token = localStorage.getItem("token");

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/s3/getdocumentsbyproperty`,
          {
            params: { propertyId: selectedProperties.join(",") },
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        setDocuments(response.data || []);
        setError(null);
      } catch (err) {
        console.error("Failed to fetch documents:", err);
        setError("Unable to fetch documents. Please try again.");
      } finally {
        setIsDocumentsLoading(false);
      }
    };

    fetchDocuments();
  }, [selectedProperties, activeTab]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        customerDropdownRef.current &&
        !customerDropdownRef.current.contains(event.target)
      ) {
        setIsCustomerDropdownOpen(false);
      }
      if (
        propertyDropdownRef.current &&
        !propertyDropdownRef.current.contains(event.target)
      ) {
        setIsPropertyDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle file upload to multiple properties
  const handleFileUpload = async () => {
    if (!selectedFile) {
      setUploadMessage("Please select a file.");
      return;
    }

    if (isUploading) return; // Prevent multiple uploads

    setIsUploading(true); // Disable further uploads while uploading

    // Validate file name for special characters
    const invalidCharacters = selectedFile.name.match(/[^a-zA-Z0-9._-]/g);
    if (invalidCharacters) {
      setUploadMessage(
        `The file name contains invalid characters, including spaces or special symbols. 
    
    To fix this:
    1. Rename your file to remove spaces or special symbols.
    2. Only use letters (A-Z), numbers (0-9), dots (.), underscores (_), or hyphens (-).
    
    Example of a valid file name: my_file-name.png`
      );
      setIsUploading(false);
      return;
    }

    try {
      const token = localStorage.getItem("token");

      for (const propertyId of selectedProperties) {
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("propertyId", propertyId);

        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/s3/uploaddocument`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      setUploadMessage("");
      setSelectedFile(null);
      // Refresh documents after upload
      setActiveTab("view");
    } catch (error) {
      console.error("File upload failed:", error);
      setUploadMessage("File upload failed. Please try again.");
    } finally {
      setIsUploading(false); // Re-enable the button
    }
  };

  // Filter customers based on search text
  const filteredCustomers = availableCustomers.filter(
    (customer) =>
      customer && // Ensure customer is not undefined or null
      typeof customer.name === "string" &&
      customer.name.toLowerCase().includes(customerSearchText.toLowerCase())
  );

  // Filter properties based on search text
  const filteredProperties = availableProperties.filter(
    (property) =>
      property && // Ensure property is not undefined or null
      typeof property.name === "string" &&
      property.name.toLowerCase().includes(propertySearchText.toLowerCase())
  );

  // Toggle property selection
  const togglePropertySelection = (propertyId) => {
    setSelectedProperties((prevSelected) =>
      prevSelected.includes(propertyId)
        ? prevSelected.filter((id) => id !== propertyId)
        : [...new Set([...prevSelected, propertyId])]
    );
  };

  const handleDocumentClick = async (key) => {
    try {
      const decodedKey = decodeURIComponent(key); // Normalize key before sending
      const token = localStorage.getItem("token");

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/s3/getpresignedurl`,
        {
          params: { key: decodedKey },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Open the presigned URL in a new tab
      window.open(response.data.url, "_blank");
    } catch (error) {
      console.error("Failed to get presigned URL:", error);
      alert("Unable to access the document. Please try again later.");
    }
  };

  const handleDocumentDownload = async (key) => {
    try {
      const decodedKey = decodeURIComponent(key); // Normalize key before sending
      const token = localStorage.getItem("token");

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/s3/getpresignedurl`,
        {
          params: { key: decodedKey },
          headers: { Authorization: `Bearer ${token}` },
          responseType: "blob", // Important: Ensure the response is treated as a binary file
        }
      );

      // Create a Blob from the response data
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);

      // Create an invisible link element
      const link = document.createElement("a");
      link.href = url;
      link.download = decodeURIComponent(key.split("/").pop()); // Use the file name from the key
      link.style.display = "none";

      // Append, click, and remove the link element
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Revoke the blob URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to download document:", error);
      alert("Unable to download the document. Please try again later.");
    }
  };

  const handleDeleteDocument = async (documentUrl, uploadedAt) => {
    console.log("Deleting document with:", { documentUrl, uploadedAt });
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this document?"
    );
    if (!confirmDelete) return;

    try {
      const token = localStorage.getItem("token");

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/s3/deletedocument`,
        { key: documentUrl, uploadedAt },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        setDocuments((prevDocs) =>
          prevDocs.filter(
            (doc) =>
              doc.DOCUMENT_URL !== documentUrl || doc.UPLOADED_AT !== uploadedAt
          )
        );
        alert("Document deleted successfully.");
      }
    } catch (error) {
      console.error("Failed to delete document:", error);
      alert("Failed to delete document. Please try again.");
    }
  };

  // Render
  if (!isOpen) return null;

  return (
    <div className="documents-modal-overlay">
      <div className="documents-modal">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>

        <div className="dropdown-section">
          {/* Customer Dropdown (Only for Admin) */}
          {isAdmin && (
            <div className="dropdown" ref={customerDropdownRef}>
              <h3 className="dropdown-header-title">Select a Customer</h3>
              <div className="dropdown-header">
                <input
                  type="text"
                  placeholder="Search customer..."
                  value={
                    isCustomerDropdownOpen
                      ? customerSearchText
                      : selectedCustomer
                      ? availableCustomers.find(
                          (c) => c.id === selectedCustomer
                        )?.name || ""
                      : ""
                  }
                  onClick={() => setIsCustomerDropdownOpen(true)}
                  onChange={(e) => setCustomerSearchText(e.target.value)}
                  className="dropdown-search-input"
                />
              </div>
              {isCustomerDropdownOpen && (
                <div className="documents-dropdown-menu">
                  {isCustomersLoading ? (
                    <div className="dropdown-loading">Loading customers...</div>
                  ) : filteredCustomers.length === 0 ? (
                    <div className="dropdown-no-results">
                      No customers found
                    </div>
                  ) : (
                    filteredCustomers.map((customer) => (
                      <div
                        key={customer.id}
                        className="dropdown-option"
                        onClick={() => {
                          setSelectedCustomer(customer.id);
                          setIsCustomerDropdownOpen(false);
                          setCustomerSearchText(""); // Clear search text on selection
                        }}
                      >
                        {customer.name}
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>
          )}

          {/* Property Dropdown */}
          <div className="dropdown" ref={propertyDropdownRef}>
            <h3 className="dropdown-header-title">Select a Property</h3>
            <div className="dropdown-header">
              <input
                type="text"
                placeholder="Search property..."
                value={
                  selectedProperties.length > 0
                    ? selectedProperties
                        .map(
                          (id) =>
                            availableProperties.find((p) => p.id === id)?.name
                        )
                        .filter(Boolean) // Filter out undefined names
                        .join(", ")
                    : propertySearchText
                }
                onClick={() => setIsPropertyDropdownOpen(true)}
                onChange={(e) => setPropertySearchText(e.target.value)}
                className="dropdown-search-input"
                disabled={!selectedCustomer}
              />
            </div>
            {isPropertyDropdownOpen && (
              <div className="documents-dropdown-menu">
                {isPropertiesLoading ? (
                  <div className="dropdown-loading">Loading properties...</div>
                ) : filteredProperties.length === 0 ? (
                  <div className="dropdown-no-results">No properties found</div>
                ) : (
                  <>
                    {/* Add "All" selection option */}
                    <div
                      className={`dropdown-option ${
                        selectedProperties.length === availableProperties.length
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => {
                        if (
                          selectedProperties.length ===
                          availableProperties.length
                        ) {
                          setSelectedProperties([]); // Deselect all
                        } else {
                          setSelectedProperties(
                            availableProperties.map((property) => property.id)
                          ); // Select all
                        }
                      }}
                    >
                      All
                    </div>
                    {filteredProperties.map((property) => (
                      <div
                        key={property.id}
                        className={`dropdown-option ${
                          selectedProperties.includes(property.id)
                            ? "selected"
                            : ""
                        }`}
                        onClick={() => togglePropertySelection(property.id)}
                      >
                        {property.name}
                      </div>
                    ))}
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="tabs">
          <button
            className={activeTab === "view" ? "tab active" : "tab"}
            onClick={() => setActiveTab("view")}
          >
            View
          </button>

          {isAdmin && (
            <button
              className={activeTab === "upload" ? "tab active" : "tab"}
              onClick={() => setActiveTab("upload")}
            >
              Upload
            </button>
          )}
        </div>

        <div className="tab-content">
          {activeTab === "view" && (
            <div className="view-section">
              <h2 className="section-title">View Documents</h2>
              {isDocumentsLoading ? (
                <p className="loading-message">Loading documents...</p>
              ) : error ? (
                <p className="error-message">{error}</p>
              ) : documents.length === 0 ? (
                <p className="no-documents-message">
                  No documents available for the selected properties.
                </p>
              ) : (
                <div className="documents-list">
                  {documents.map((doc, index) => (
                    <div key={doc.DOCUMENT_URL} className="document-row">
                      <div className="document-info">
                        <h3 className="document-title">
                          {decodeURIComponent(
                            doc.DOCUMENT_URL.split("/").pop()
                          )}
                        </h3>
                        <p className="document-uploaded">
                          Uploaded: {new Date(doc.UPLOADED_AT).toLocaleString()}
                        </p>
                      </div>
                      <div className="document-actions">
                        {/* View Button */}
                        <button
                          id={`view-tooltip-${index}`}
                          onClick={() =>
                            handleDocumentClick(
                              doc.DOCUMENT_URL,
                              doc.UPLOADED_AT
                            )
                          }
                          className="document-action-button"
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </button>
                        <Tooltip
                          anchorId={`view-tooltip-${index}`}
                          content="View"
                          place="top"
                        />

                        {/* Download Button */}
                        <button
                          id={`download-tooltip-${index}`}
                          onClick={() =>
                            handleDocumentDownload(doc.DOCUMENT_URL)
                          }
                          className="download-action-button"
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </button>
                        <Tooltip
                          anchorId={`download-tooltip-${index}`}
                          content="Download"
                          place="top"
                        />

                        {/* Delete Button (only shown if the user is an admin) */}
                        {isAdmin && (
                          <button
                            id={`delete-tooltip-${index}`}
                            onClick={() =>
                              handleDeleteDocument(
                                doc.DOCUMENT_URL,
                                doc.UPLOADED_AT
                              )
                            }
                            className="delete-action-button"
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </button>
                        )}
                        {isAdmin && (
                          <Tooltip
                            anchorId={`delete-tooltip-${index}`}
                            content="Delete"
                            place="top"
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          {activeTab === "upload" && (
            <div className="upload-section">
              <p className="upload-warning">
                Note: The file will be uploaded to all selected properties.
              </p>
              <input
                type="file"
                onChange={(e) => {
                  setSelectedFile(e.target.files[0]);
                  console.log("Selected file:", e.target.files[0]); // Debugging
                }}
                className="file-input"
              />
              <button
                className="upload-button"
                onClick={handleFileUpload}
                disabled={
                  selectedProperties.length === 0 ||
                  !selectedFile ||
                  isUploading
                }
              >
                {isUploading ? "Uploading..." : "Upload File"}
              </button>

              {uploadMessage && (
                <p className="upload-message">{uploadMessage}</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentsModal;
